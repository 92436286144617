<template>
	<Transition name="opacity-transition">
		<slot></slot>
	</Transition>
</template>
<style>
.opacity-transition-enter-active,
.opacity-transition-leave-active {
	transition: opacity 100ms ease;
}

.opacity-transition-enter-from,
.opacity-transition-leave-to {
	opacity: 0;
}
</style>

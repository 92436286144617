export default defineNuxtPlugin(async () => {
	const { storage } = usePageSyncPipeline();

	if (storage.value.length === 0) return;

	addAlert(
		"Some of your data from the last session have not yet been synced. It's recommended that you refresh your page so you can see the latest changes.",
		"success",
	);
});

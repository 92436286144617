<template>
	<div class="relative">
		<div ref="targetRef">
			<slot />
		</div>
		<opacity-transition>
			<div
				v-if="!shouldOpen"
				ref="containerRef"
				class="accent-body inset-center-x absolute mt-16"
				style="z-index: 9999">
				<div class="w-[18rem]">
					<div class="nudge w-full rounded-md border border-gray-200 bg-white px-4 py-2">
						<div class="flex w-full flex-row items-center justify-between">
							<h1 class="text-sm font-bold">
								{{ title }}
							</h1>
							<div>
								<button
									class="text-gray-400 transition-colors hover:text-gray-600"
									@click="open = 'false'">
									<XMarkIcon class="h-4 w-4" />
								</button>
							</div>
						</div>
						<p class="text-sm text-gray-500">
							{{ description }}
						</p>
						<div
							v-if="action"
							class="mt-4">
							<button
								class="rounded-md bg-black px-4 py-1 text-sm font-bold text-white"
								@click.prevent="doAction">
								{{ action }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</opacity-transition>
	</div>
</template>
<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";

const props = defineProps<{
	id: string;
	title: string;
	description: string;
	action: string | null;
}>();
const emits = defineEmits(["action"]);
const open = useCookie("nudge" + props.id, {
	maxAge: 60 * 60 * 24 * 30,
}); // 30 days

const targetRef = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);

const shouldOpen = computed(() => {
	return !open.value;
});

function doAction() {
	emits("action");
	open.value = "false";
}
</script>
<style>
.nudge {
	-webkit-box-shadow: 0 0 100px -20px rgba(230, 71, 230, 0.22);
	-moz-box-shadow: 0 0 100px -20px rgba(230, 71, 230, 0.22);
	box-shadow: 0 0 100px -20px rgba(230, 71, 230, 0.22);
}
</style>

export function mdy(input?: string | Date): string {
	if (!input) {
		return "N/A";
	}
	const date = new Date(input);

	return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function wasTimeAgo(input: string, mills: number) {
	const date = new Date(input);
	const now = new Date();

	return now.getTime() - date.getTime() > mills;
}

export function numberToTime(input: number) {
	const seconds = Math.floor(input / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);

	if (hours > 0) {
		return `${hours} hours`;
	}

	if (minutes > 0) {
		return `${minutes} minutes`;
	}

	if (seconds > 0) {
		return `${seconds} seconds`;
	}

	return "0 seconds";
}

export function timeAgoPretty(input?: string | Date) {
	if (!input) {
		return "Never";
	}

	const date = new Date(input);
	const now = new Date();

	const diff = now.getTime() - date.getTime();

	if (diff < 1000) {
		return "just now";
	}

	if (diff < 60000) {
		return `${Math.floor(diff / 1000)} seconds ago`;
	}

	if (diff < 3600000) {
		return `${Math.floor(diff / 60000)} minutes ago`;
	}

	if (diff < 86400000) {
		return `${Math.floor(diff / 3600000)} hours ago`;
	}

	return `${Math.floor(diff / 86400000)} days ago`;
}

export function hasTimePassed(input: string, mills: number) {
	const date = new Date(input);
	const now = new Date();

	return now.getTime() - date.getTime() >= mills;
}

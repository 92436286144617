<template>
	<div
		ref="container"
		class="relative"
		@click="checkClose">
		<slot :open="open" />
		<teleport to="body">
			<menu-transition>
				<dropdown
					v-if="open"
					:overridePrimaryStyle="{
						position: 'fixed',
						left: position.x + 'px',
						top: position.y + 'px',
					}"
					:style="{
						...dropdownStyle,
					}"
					:width="props.dropdownWidth || '200px'"
					@click="checkBtnClickClose"
					@close="close()">
					<slot name="dropdown" />
				</dropdown>
			</menu-transition>
		</teleport>
	</div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

const props = defineProps<{
	dropdownWidth?: string;
	alignment?: "left" | "right";
}>();

const open = ref(false);
const container = ref<HTMLElement | null>(null);
const dropdownStyle = ref<Partial<CSSStyleDeclaration>>({});
const emits = defineEmits(["open", "close"]);

watch(open, () => {
	calcPosition();
});

const position = reactive({
	x: 0,
	y: 0,
});

onMounted(() => {
	const button = container.value?.querySelector("[data-sc-dropdown-btn]");
	button?.addEventListener("click", toggle);

	calcPosition();
});

onUnmounted(() => {
	const button = container.value?.querySelector("[data-sc-dropdown-btn]");
	button?.removeEventListener("click", toggle);
});

function calcPosition() {
	const button = container.value?.querySelector("[data-sc-dropdown-btn]");

	if (!button) {
		return;
	}
	const rect = button.getBoundingClientRect();

	if (props.alignment === "right") {
		let width_num: number = (props.dropdownWidth || '200px').replace("px", "")
		position.x = rect.left - width_num + rect.width;
	} else {
		position.x = rect.left;
	}

	position.y = rect.bottom + 5;
}

function checkClose(e: MouseEvent) {
	//if any parent has [data-sc-dropdown-btn-close] attribute, close the dropdown
	if (e.target instanceof HTMLElement && e.target.closest("[data-sc-dropdown-btn-close]")) {
		close();
	}
}

function close() {
	open.value = false;

	emits("close");
}

function toggle() {
	open.value = !open.value;

	if (!open.value) {
		emits("close");
	}
}

function checkBtnClickClose(e: MouseEvent) {
	if (e.target instanceof HTMLElement) {
		const btn = e.target;

		if (!btn.closest("[data-sc-dropdown-btn-close]")) {
			return;
		}
		close();
	}
}
</script>

import { useHomeData } from "~/composables/useHomeData";

export default defineNuxtPlugin(async () => {
	const { shouldFetchChartData, fetchChartData } = useHomeData();
	const { user} = useAuth();

	setInterval(
		() => {
			if (!user.value) return;

			if (shouldFetchChartData()) {
				fetchChartData();
			}
		},
		1000 * 60 * 5,
	); // Every 5 mins
});

<template>
	<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Import</h1>
	<div class="mt-4">
		<p class="text-sm font-semibold text-black">Import your data into Scholarly.</p>
		<p class="text-sm text-gray-800">We currently support a limited number of import formats, if your data is not supported, try importing it via CSV file.</p>
		<input
			ref="fileInput"
			:accept="ACCEPTED_IMPORT_FORMATS.join(',')"
			class="hidden"
			multiple
			type="file"
			@change="doDaImPoRT()" />
		<div class="mt-4 grid grid-cols-4 gap-4 text-center">
			<modal
				v-if="isParsingFile"
				@close="isParsingFile = false">
				<div class="flex flex-col items-center justify-center">
					<h1
						v-if="parsingFileError === null"
						class="font-medium">
						Loading your file...
					</h1>
					<h1
						v-else
						class="font-medium">
						{{ parsingFileError }}
					</h1>
				</div>
			</modal>
			<button
				class="scholarly-button-big"
				@click="openFileInput">
				<FileTextIcon class="h-4 w-4" />
				<span>Markdown</span>
			</button>
			<button
				class="scholarly-button-big"
				@click="openFileInput">
				<PencilIcon class="h-4 w-4" />
				<span>Text File</span>
			</button>
			<button
				class="scholarly-button-big"
				@click.prevent="quizletModalOpen = !quizletModalOpen">
				<nuxt-img
					format="webp"
					height="auto"
					loading="lazy"
					src="/import_misc/quizlet_logo.png"
					width="80px" />
			</button>
			<button
				class="scholarly-button-big"
				@click.prevent="openFileInput">
				<FileSpreadsheet class="h-4 w-4" />
				<span> CSV </span>
			</button>
		</div>
	</div>
	<menu-transition>
		<div
			v-if="quizletModalOpen"
			class="mt-4 rounded-md border border-gray-150 px-4 py-2">
			<h1 class="text-lg font-bold text-black">How to get your Quizlet set</h1>
			<ul class="ml-4 mt-2 list-decimal text-base text-black">
				<li>Open your Quizlet set</li>
				<li>Click the three dots in the top right corner next to your profile picture</li>
				<li>Click "Export"</li>
				<li>Click "Copy text"</li>
				<li>Paste the text into the box below</li>
			</ul>
			<div>
				<div class="styled-input mt-4">
					<textarea
						v-model="quizletSet"
						class="h-32 w-full"
						placeholder="Your quizlet set" />
				</div>
				<spinning-button
					class="mt-4"
					@click="importQuizlet">
					Import
				</spinning-button>
			</div>
		</div>
	</menu-transition>
	<div class="mt-12">
		<p class="text-base font-medium text-gray-500">Not sure what file type to use? Directly upload a file below.</p>
		<button
			:class="{ '!border-secondary/20': isDragging }"
			class="mt-4 w-full rounded-md border border-gray-100 bg-gray-50 px-4 py-4 text-sm hover:bg-gray-100"
			@click="openFileInput"
			@dragenter="isDragging = true"
			@dragleave="isDragging = false"
			@dragover.prevent
			@drop.prevent="handleDrop">
			Drag n' Drop, or click here to upload a file
		</button>
	</div>
</template>
<script lang="ts" setup>
import { FileSpreadsheet, FileTextIcon, PencilIcon } from "lucide-vue-next";
import { createComponent } from "~/utils/component/component_utils";
import { Component, ComponentFlashCards, ComponentType, FlashCard } from "~/utils/page";
import { TextCreator, TextSubType } from "@scholarly/components";
import { parseComponentsFromMarkdownLines } from "~/utils/text/text_utils";

const { createPage, getFullComponents } = usePages();
const { user } = useAuth();
const { toggleSettingsModal } = useUserSettings();

const ACCEPTED_IMPORT_FORMATS = [".txt", ".md", ".csv"];

const fileInput = ref<HTMLInputElement | null>(null);

const quizletModalOpen = ref(false);
const quizletSet = ref("");

const isParsingFile = ref(false);
const parsingFileError = ref<string | null>(null);

const isDragging = ref(false);

function handleDrop(e: DragEvent) {
	const files = e.dataTransfer?.files;

	if (!files) {
		return;
	}

	doImport(Array.from(files));
}

function doDaImPoRT() {
	const files = fileInput.value?.files;

	if (!files) {
		return;
	}

	doImport(Array.from(files));
}

function doImport(files: File[]) {
	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const extension = file.name.split(".").pop();

		if (extension === "txt") {
			importTxt(file);
		} else if (extension === "md") {
			importMarkdown(file);
		} else if (extension === "csv") {
			importCSV(file);
		} else {
			addAlert("Unsupported file format", "error");
			return;
		}
	}

	addAlert("Imported data successfully", "success");

	getPosthog().capture("imported_data", {
		user_id: user.value?._id,
		files: files.length,
	});
}

function importTxt(file: File) {
	const reader = new FileReader();
	const lines: string[] = [];

	reader.onload = async (e) => {
		const content = e.target?.result;

		if (typeof content === "string") {
			lines.push(...content.split("\n"));
		}

		const page = await createPage(undefined, file.name);
		const components = getFullComponents(page._id);

		let lastComponent: Component | undefined = undefined;

		lines.forEach((line) => {
			//get rid of any leading/trailing whitespace
			line = line.trim();

			lastComponent = createComponent(ComponentType.TEXT, page, components.value, {
				content: {
					text: new TextCreator().append(line).get(),
					subType: TextSubType.NORMAL,
					metadata: {
						indents: 0,
						highlightColor: undefined,
					},
				},
				insertAfter: lastComponent,
			});
		});

		isParsingFile.value = false;
		toggleSettingsModal();
	};
	isParsingFile.value = true;
	reader.readAsText(file);
}

function importCSV(file: File) {
	const reader = new FileReader();
	const lines: string[] = [];

	reader.onload = async (e) => {
		const content = e.target?.result;

		if (typeof content === "string") {
			lines.push(...content.split("\n"));
		}

		const cards: FlashCard[] = [];
		let ignoredFirstLine = false;

		lines.forEach((line) => {
			if (!ignoredFirstLine) {
				ignoredFirstLine = true;
				return;
			}

			const [front, back] = line.split(",");
			cards.push({
				question: front,
				answer: back,
				id: randomUUID(),
			});
		});

		const page = await createPage(undefined, file.name);
		const components = getFullComponents(page._id);
		const component = createComponent(ComponentType.FLASH_CARDS, page, components.value, {
			content: {
				title: "Flashcard Deck",
				cards: cards,
				minimized: false,
			},
			insertAfter: undefined,
		}) as ComponentFlashCards;

		component.shouldGenTitle = true;
		isParsingFile.value = false;
		toggleSettingsModal();
	};

	reader.readAsText(file);
}

function importMarkdown(file: File) {
	const reader = new FileReader();
	const lines: string[] = [];

	reader.onload = async (e) => {
		const content = e.target?.result;

		if (typeof content === "string") {
			lines.push(...content.split("\n"));
		}

		const page = createPage(undefined, file.name);
		parseComponentsFromMarkdownLines(
			lines.map((x) => x.trim()),
			page,
		);

		isParsingFile.value = false;
		toggleSettingsModal();
	};
	isParsingFile.value = true;
	reader.readAsText(file);
}

function openFileInput() {
	fileInput.value?.click();
}

let isImporting = false;

async function importQuizlet() {
	if (isImporting) {
		return;
	}
	isImporting = true;
	const cards = quizletSet.value.split("\n").map((line) => {
		const [front, back] = line.split("\t");
		return {
			question: front,
			answer: back,
			id: randomUUID(),
		};
	});
	isImporting = true;
	quizletModalOpen.value = false;
	quizletSet.value = "";

	const page = await createPage(undefined, "Quizlet Set");
	const components = getFullComponents(page._id);
	const component = createComponent(ComponentType.FLASH_CARDS, page, components.value, {
		content: {
			title: "Flashcard Deck",
			cards: cards,
			minimized: false,
		},
		insertAfter: undefined,
	}) as ComponentFlashCards;
	component.shouldGenTitle = true;
	isImporting = false;
	addAlert("Imported Quizlet set successfully", "success");
	toggleSettingsModal();
}
</script>

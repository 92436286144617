import { SpecialTagType, TextAttribute, TextComponentContent, TextCreator, TextNode, TextSubType } from "@scholarly/components";
import { Component, ComponentType, Page } from "~/utils/page";
import { createComponentTransaction } from "~/utils/component/component_utils";

//todo: export this to the libs repo, I just don't know how to do mono repos in NodeJS

//<TextSubType>: <string>
const MARKDOWN_SUBTYPES: Record<string, string> = {
	// NORMAL: "", //ignore normal as it's the default
	HEADING_1: "# ",
	HEADING_2: "## ",
	HEADING_3: "### ",
	LIST_ORDERED: "1. ",
	LIST_UNORDERED: "- ",
	BLOCK_QUOTE: "> ",
	LIST_CHECKBOX_CHECKED: "- [x] ",
	LIST_CHECKBOX_UNCHECKED: "- [ ] ",
};

export function parseMarkdownAttributes(line: string) {
	const node = new TextCreator().get();
	let index = 0;
	let starting = 0;

	const pushNodeContent = () => {
		if (node.children.length > 0 || node.content.length !== 0) {
			node.children.push(new TextCreator().append(line.substring(starting, index)).get());
		} else {
			node.content = line.substring(starting, index);
		}
	};

	const locateEnding = (key: string, start: number): number => {
		let j = start;

		while (j < line.length) {
			if (line.slice(j, j + key.length) === key) {
				return j;
			}
			j++;
		}

		return -1;
	};

	const goesToKey = (key: string) => {
		return line.slice(index, index + key.length) === key;
	};

	const applyStyle = (key: string, endIndex: number, style: TextAttribute) => {
		const styledNode = parseMarkdownAttributes(line.substring(index + key.length, endIndex));

		styledNode.attributes.push(style);
		node.children.push(styledNode);

		index = endIndex + key.length;
		starting = index;
	};

	const parseKey = (key: string, style: TextAttribute) => {
		if (goesToKey(key)) {
			let endIndex = locateEnding(key, index + key.length + 1);

			if (endIndex !== -1) {
				pushNodeContent();
				applyStyle(key, endIndex, style);
			}
		}
	};

	while (index < line.length) {
		parseKey("**", TextAttribute.BOLD);
		parseKey("__", TextAttribute.UNDERLINE);
		parseKey("*", TextAttribute.ITALIC);
		parseKey("_", TextAttribute.ITALIC);
		parseKey("~~", TextAttribute.STRIKETHROUGH);
		parseKey("`", TextAttribute.INLINE_CODE);

		index++;
	}

	pushNodeContent();

	return node;
}

export function mutateTextSubtypeFromMarkdown(text: TextComponentContent, childIndex: number = -1): TextComponentContent {
	let node = text.text;

	if (childIndex !== -1) {
		node = text.text.children[childIndex];
	}
	if (!node) {
		return text;
	}
	// node = parseMarkdownAttributes(node);
	const content = node.content;

	for (const subtype in MARKDOWN_SUBTYPES) {
		if (content.startsWith(MARKDOWN_SUBTYPES[subtype])) {
			text.subType = subtype.toLowerCase() as TextSubType;
			node.content = content.substring(MARKDOWN_SUBTYPES[subtype].length);

			return text;
		}
	}

	return text;
}

export function parseComponentsFromMarkdownLines(lines: string[], page: Page, insertAfter?: Component, fadeIn?: boolean = true) {
	if (lines.length === 0) {
		return [];
	}
	const components = usePages().getFullComponents(page._id);
	const transaction = createComponentTransaction(page, components.value, { insertAfter });

	for (let line of lines) {
		//code block
		if (line.startsWith("```") && line.endsWith("```")) {
			transaction.add(ComponentType.CODE_BLOCK, {
				//todo: parse language from the first line
				language: "plaintext",
				code: line.substring(3, line.length - 3).trim(),
			});
			continue;
		}

		//equation
		if (line.startsWith("$$") && line.endsWith("$$")) {
			transaction.add(ComponentType.EQUATION, {
				text: line.substring(2, line.length - 2).trim(),
			});
			continue;
		}

		if (line.startsWith("---")) {
			transaction.add(ComponentType.DIVIDER, {});
			continue;
		}

		let subType = TextSubType.NORMAL;
		let isHeading4 = false;

		// determine indentation
		let indents = 0;
		while (line[indents] === " ") {
			indents++;
		}
		line = line.trim();

		if (line.startsWith("#### ")) {
			line = line.substring(5);

			isHeading4 = true;
		}

		if (line.startsWith("### ")) {
			subType = TextSubType.HEADING_3;
			line = line.substring(4);
		} else if (line.startsWith("## ")) {
			subType = TextSubType.HEADING_2;
			line = line.substring(3);
		} else if (line.startsWith("# ")) {
			subType = TextSubType.HEADING_1;
			line = line.substring(2);
		} else if (line.startsWith("- [x] ") || line.startsWith("[x]")) {
			subType = TextSubType.LIST_CHECKBOX_CHECKED;
			line = line.substring(6);
		} else if (line.startsWith("- [ ] ") || line.startsWith("[ ]")) {
			subType = TextSubType.LIST_CHECKBOX_UNCHECKED;
			line = line.substring(6);
		} else if (line.startsWith("- ") || line.startsWith("* ")) {
			subType = TextSubType.LIST_UNORDERED;
			line = line.substring(2);
		} else if (line.startsWith("> ")) {
			subType = TextSubType.BLOCK_QUOTE;
			line = line.substring(2);
		} else {
			const firstWord: string = line[0];

			if (Number.isInteger(parseInt(firstWord))) {
				subType = TextSubType.LIST_ORDERED;
				line = line.substring(2);
			}
		}

		//text
		const content: TextComponentContent = mutateTextSubtypeFromMarkdown({
			subType: subType,
			metadata: {
				indents: indents,
				highlightColor: undefined,
			},
			text: parseMarkdownAttributes(line),
		});

		if (isHeading4) {
			content.text.attributes.push(TextAttribute.BOLD);
		}

		transaction.add(ComponentType.TEXT, content);
	}
	transaction.finish();

	const newComponents = transaction.newComponents;

	if (fadeIn) {
		for (const component of newComponents) {
			//@ts-ignore
			component.fadeIn = fadeIn;
		}
	}

	return newComponents;
}

export type ProseMirrorJsonData = {
	type: string;
	marks: any[];
	text: string;
};

export function parseProseMirror(proseJson: any): TextNode {
	const content: TextNode[] = [];

	if (!proseJson) {
		return {
			content: "",
			children: [],
			attributes: [],
		};
	}

	const proseContent = proseJson.content as ProseMirrorJsonData[];

	if (!proseContent) {
		return {
			content: "",
			children: [],
			attributes: [],
		};
	}

	for (const node of proseContent) {
		if (node.type !== "text") {
			console.error("Prose Node type is not text");
			continue;
		}

		let text: TextNode = {
			content: node.text,
			children: [],
			attributes: [],
		};

		if (node.marks) {
			for (const mark of node.marks) {
				if (mark.type === "strong") {
					text.attributes.push(TextAttribute.BOLD);
				}
				if (mark.type === "em") {
					text.attributes.push(TextAttribute.ITALIC);
				}
				if (mark.type === "underline") {
					text.attributes.push(TextAttribute.UNDERLINE);
				}
				if (mark.type === "s") {
					text.attributes.push(TextAttribute.STRIKETHROUGH);
				}
				if (mark.type === "inline_code") {
					text.attributes.push(TextAttribute.INLINE_CODE);
				}
				if (mark.type === "link") {
					let url = mark.attrs.href;

					text.tag = {
						type: SpecialTagType.LINK,
						metadata_link: {
							url,
						},
					};
				}
				if (mark.type === "page_tag") {
					let pageId = mark.attrs.pageId;

					text.tag = {
						type: SpecialTagType.PAGE_TAG,
						metadata_page_tag: {
							pageId,
						},
					};
				}
				if (mark.type === "flashcard_tag") {
					let flashcardId = mark.attrs.flashcardId;

					text.tag = {
						type: SpecialTagType.FLASHCARD_TAG,
						metadata_flashcard_tag: {
							flashcardId,
						},
					};
				}
				if (mark.type === "pdf_tag") {
					let pdfId = mark.attrs.pdfId;

					text.tag = {
						type: SpecialTagType.PDF_TAG,
						metadata_pdf_tag: {
							pdfId,
						},
					};
				}
			}
		}
		content.push(text);
	}
	const creator = new TextCreator();
	for (const node of content) {
		creator.append(node);
	}

	return creator.get();
}

export const TagsAttributes = {
	bold: "strong",
	italic: "em",
	underline: "u",
	strikethrough: "s",
	inline_code: "code",
};

export function toHTML(content: TextNode) {
	let html = content.content;

	for (const child of content.children) {
		let toAdd = toHTML(child);
		toAdd = content.content + toAdd;

		if (child.attributes.length > 0) {
			let prefix = "";
			let suffix = "";
			for (const attribute of child.attributes) {
				//@ts-ignore
				prefix += `<${TagsAttributes[attribute]}>`;
				//@ts-ignore
				suffix = `</${TagsAttributes[attribute]}>` + suffix;
			}

			//what about child.content
			html += prefix + toAdd + suffix;
		} else {
			html += toAdd;
		}
	}

	if (!html) {
		html = "<br>";
	}

	return html;
}

export function toPlainText(node: TextNode): string {
	let markdown = "";
	markdown += node.content;
	for (const child of node.children) {
		markdown += toPlainText(child);
	}
	return markdown;
}

<template>
	<div @drag="handleDragMove">
		<div class="flex flex-row items-center justify-between">
			<div class="flex flex-row items-center gap-2">
				<h3 class="text-xs font-medium text-black">Pages</h3>
				<client-only>
					<button
						class="pr-4"
						@click.prevent="preferences.sidebar.pages_minimized = !preferences.sidebar.pages_minimized">
						<animated-chevron-icon
							:mode="preferences.sidebar.pages_minimized ? 'right' : 'down'"
							class="h-2.5 w-2.5" />
					</button>
				</client-only>
			</div>
			<div class="flex flex-row items-center gap-1">
				<tooltip tooltip="Create a new page">
					<button
						aria-label="Create Page"
						class="rounded-md p-1 text-black hover:bg-gray-100"
						@click.prevent="processCreateButton()">
						<PlusIcon class="h-4 w-4" />
					</button>
				</tooltip>
				<tooltip tooltip="Cleanup Unused Pages">
					<button
						class="ai-tool-color rounded-md p-1 text-black hover:bg-gray-100"
						@click.prevent="isMassArchive = true">
						<BroomIcon class="h-4 w-4" />
					</button>
				</tooltip>
				<!--				<dropdown-container>-->
				<!--					<tooltip tooltip="Smart/AI Tools">-->
				<!--						<dropdown-open-button class="ai-tool-color p-1 text-black hover:bg-gray-100 rounded-md">-->
				<!--							<SparklesIcon class="h-4 w-4" />-->
				<!--						</dropdown-open-button>-->
				<!--					</tooltip>-->
				<!--					<template #dropdown>-->
				<!--						<dropdown-item @click="isMassArchive = true"> Archive Unused Pages</dropdown-item>-->
				<!--					</template>-->
				<!--				</dropdown-container>-->
			</div>
		</div>
		<client-only>
			<Collapse
				:when="!preferences.sidebar.pages_minimized"
				class="!duration-100">
				<div
					v-for="(element, index) in sortedPages"
					:key="element._id"
					draggable="true">
					<page-sort-divider
						:above="sortedPages[index - 1]"
						:below="sortedPages[index]"
						:closest-id="closestDragId" />
					<sidebar-page-editor
						:closestDragId="closestDragId"
						:page="element"
						@set-drag-target="(x, y) => startDragPage(x, y)" />
				</div>
				<page-sort-divider
					:above="sortedPages[sortedPages.length - 1]"
					:below="null"
					:closest-id="closestDragId" />
			</Collapse>
		</client-only>
		<div v-if="sortedPages.length === 0">
			<div class="rounded-md bg-gray-100 px-4 py-2">
				<h1 class="text-sm text-gray-600">You don't have any pages yet. Click the plus button to create one.</h1>
			</div>
		</div>
	</div>
	<unused-pages-cleanup-modal
		v-if="isMassArchive"
		@close="isMassArchive = false" />
</template>
<script lang="ts" setup>
import axios from "axios";
import type { Page } from "~/utils/page";
import type { Ref } from "vue";
import { Collapse } from "vue-collapsed";
import { PlusIcon } from "lucide-vue-next";

const { pages } = usePages();
const { preferences } = useUserPreferences();
const sortedPages = ref(pages.value.filter((p) => !p.archived && p.parent == null).sort((a, b) => a.order - b.order));

const closestDragId = ref(null) as Ref<string | null>;

const isMassArchive = ref(false);

watch(
	pages,
	(newPages) => {
		sortedPages.value = newPages.filter((p) => !p.archived && p.parent == null).sort((a, b) => a.order - b.order);
	},
	{ deep: true },
);

const { dragTarget, isDragOverFavorite } = usePagesDrag();

function handleDragMove(e: DragEvent) {
	const x = e.clientX;
	const y = e.clientY;

	//if it was caused by drag end
	if (x === 0 && y === 0) {
		endDrag();
		return;
	}

	if (dragTarget.value === null) {
		return;
	}

	if (isDragOverFavorite.value) {
		closestDragId.value = null;
		return;
	}

	const allComponents = document.querySelectorAll("[data-page-sort-divider]");

	//find the closest component
	let closestDistance = Infinity;
	let closestId = null;

	for (const component of allComponents) {
		const rect = component.getBoundingClientRect();
		const distance = Math.sqrt(Math.pow(x - rect.left, 2) + Math.pow(y - rect.top, 2));

		if (distance < closestDistance) {
			closestDistance = distance;
			closestId = (component as HTMLElement).dataset.pageSortDivider as string;
		}
	}

	if (closestDistance > 600) {
		closestId = null;
	}

	closestDragId.value = closestId;
}

function startDragPage(page: Page, e: DragEvent) {
	dragTarget.value = page;

	e.dataTransfer!!.setData("text/plain", "Scholarly Page Drag");
}

const { addFavorite } = useFavorites();

async function endDrag() {
	if (isDragOverFavorite.value && dragTarget.value) {
		await addFavorite(dragTarget.value!!._id, "page", dragTarget.value);
		getPosthog().capture("page_favorite_added_via_drag", { pageId: dragTarget.value!!._id });
		return;
	}

	const element = document.querySelector(`[data-page-sort-divider="${closestDragId.value}"]`) as HTMLElement;

	if (element === null) {
		return;
	}

	const above = element.dataset.pageSortDividerAbove;
	const below = element.dataset.pageSortDividerBelow;
	const parent = element.dataset.pageSortDividerParent as string | undefined;

	if (parent) {
		if (parent === dragTarget.value!!._id) {
			dragTarget.value = null;
			closestDragId.value = null;
			return;
		}

		dragTarget.value!!.parent = parent;

		const parentPage = pages.value.find((p) => p._id === parent)!!;

		removeItem(parentPage.children, dragTarget.value!!);

		if (!above) {
			parentPage.children.unshift(dragTarget.value!!);
		} else if (!below) {
			parentPage.children.push(dragTarget.value!!);
		} else {
			insertItemBetween<Page>(
				parentPage.children,
				dragTarget.value!!,
				parentPage.children.find((s) => s._id === above)!!,
				parentPage.children.find((s) => s._id === below)!!,
			);
		}

		//remove from sorted pages
		removeItem(sortedPages.value, dragTarget.value!!);

		//set order
		parentPage.children.forEach((p, i) => {
			p.order = i;
		});

		axios
			.post(`/pages/${dragTarget.value!!._id}/sync_sorting`, {
				parentId: parent,
				order: parentPage.children.map((p) => p._id),
			})
			.then(() => {})
			.catch((e) => {
				console.error(e);
			});

		dragTarget.value = null;
		closestDragId.value = null;

		return;
	}

	if (dragTarget.value!!.parent) {
		axios
			.post(`/pages/${dragTarget.value!!._id}/sync_sorting`, {
				parentId: null,
			})
			.then(() => {})
			.catch((e) => {
				console.error(e);
			});
		//remove from children
		const parentPage = pages.value.find((p) => p._id === dragTarget.value!!.parent)!!;

		removeItem(parentPage.children, dragTarget.value!!);

		dragTarget.value!!.parent = null;

		//add to sorted pages
		sortedPages.value.push(dragTarget.value!!);
	}

	handleNoParentPageSort({
		page: dragTarget.value!!,
		above: above ? (above as string) : null,
		below: below ? (below as string) : null,
	})
		.then(() => {})
		.catch((e) => {
			console.error(e);
		});

	dragTarget.value = null;
	closestDragId.value = null;
}

async function handleNoParentPageSort(event: { page: Page; above: string | null; below: string | null }) {
	const { page, above, below } = event;

	sortedPages.value.splice(sortedPages.value.indexOf(page), 1);

	if (!above) {
		sortedPages.value.unshift(page);
	} else if (!below) {
		sortedPages.value.push(page);
	} else {
		insertItemBetween<Page>(sortedPages.value, page, sortedPages.value.find((s) => s._id === above)!!, sortedPages.value.find((s) => s._id === below)!!);
	}

	//set order
	sortedPages.value.forEach((p, i) => {
		p.order = i;
	});

	try {
		await axios.post("/pages/sort", {
			ids: sortedPages.value.map((p) => p._id),
		});
	} catch (e) {
		console.error(e);
	}
}

async function processCreateButton() {
	const { createPage } = usePages();

	try {
		await createPage();
	} catch (e) {
		console.error(e);
	}
}
</script>

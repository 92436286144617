<template>
	<modal-with-sidebar
		:title="undefined"
		@close="$emit('close')">
		<template #sidebar>
			<div>
				<h1 class="text-sm font-bold text-gray-600">Account</h1>
				<div class="mt-2 flex flex-row items-center space-x-3">
					<img
						:src="user!!.profilePictureUrl"
						alt=""
						class="h-9 w-9 rounded-full shadow-sm"
						loading="lazy" />
					<div>
						<h1 class="font-medium">{{ user!!.name }}</h1>
						<h2 class="text-sm text-gray-500">
							{{ user!!.email }}
						</h2>
					</div>
				</div>
				<div class="mt-4 space-y-0.5">
					<button
						v-for="page of accountPages"
						:key="page.id"
						:class="{
							'bg-gray-200 font-bold': userSettingsSelectedPage === page.id,
							'font-medium': userSettingsSelectedPage !== page.id,
						}"
						class="flex w-full flex-row items-center space-x-2 rounded-md px-2 py-1 text-sm text-gray-600 hover:bg-gray-200"
						@click="userSettingsSelectedPage = page.id">
						<component
							:is="page.icon"
							class="h-4 w-4" />
						<span>
							{{ page.name }}
						</span>
					</button>
				</div>
			</div>
			<div class="mt-4">
				<h1 class="text-sm font-bold text-gray-600">Content</h1>
				<div class="space-y-0.5">
					<button
						v-for="page of contentPages"
						:key="page.id"
						:class="{
							'bg-gray-200 font-bold': userSettingsSelectedPage === page.id,
							'font-medium': userSettingsSelectedPage !== page.id,
						}"
						class="flex w-full flex-row items-center space-x-2 rounded-md px-2 py-1 text-sm text-gray-600 hover:bg-gray-200"
						@click="userSettingsSelectedPage = page.id">
						<component
							:is="page.icon"
							class="h-4 w-4" />
						<span>
							{{ page.name }}
						</span>
					</button>
				</div>
			</div>
		</template>
		<template #main>
			<div class="max-h-[630px] min-h-[630px] w-[700px] overflow-y-auto">
				<div class="pb-4 pr-4">
					<div v-if="userSettingsSelectedPage === 'my_account'">
						<div>
							<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Profile</h1>
							<div class="mt-4">
								<img
									:src="user!!.profilePictureUrl"
									alt=""
									class="h-24 w-24 rounded-full shadow-sm transition hover:brightness-95"
									loading="lazy"
									@click="openProfileUpload" />
								<input
									ref="profileUploadInput"
									accept="image/jpeg,image/png"
									class="hidden"
									type="file"
									@change="uploadProfilePicture" />
								<button
									class="mt-3 rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100"
									@click="openProfileUpload">
									Upload new picture
								</button>
							</div>
							<div class="mt-4">
								<h1 class="text-sm font-semibold">Full name</h1>
								<div class="styled-input mt-1">
									<input
										v-model="name"
										type="text" />
								</div>
							</div>
						</div>
						<div class="mt-10">
							<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Assistant</h1>
							<div class="mt-4 flex flex-row items-start justify-between">
								<div>
									<h1 class="text-sm font-semibold">Language</h1>
									<p class="mt-1 max-w-[350px] text-xs text-gray-500">
										Change the language that Scholarly's Assistant, PDF to Flashcards, and other features use.
									</p>
								</div>
								<language-selector class="mt-2" :dark="false"/>
							</div>
						</div>
						<div class="mt-10">
							<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Security</h1>
							<div class="mt-4">
								<h1 class="text-sm font-semibold">Email address</h1>
								<div class="styled-input mt-1">
									<input
										v-model="email"
										class="w-full cursor-not-allowed"
										disabled
										type="text" />
								</div>
								<p class="mt-2 text-xs text-gray-500">
									Your email address is used to log in and cannot be changed. <br />If you need to change your email address, please contact
									<a
										class="text-primary underline"
										href="mailto:hello@scholarly.so"
										>support</a
									>.
								</p>
							</div>
							<div class="mt-4 flex flex-row items-center justify-between">
								<div>
									<h1 class="text-sm font-semibold">Password</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">
										Change your password by clicking the button to the right. You'll be sent an email with a link to reset your password.
									</p>
								</div>
								<button
									class="rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100"
									@click="sendPasswordReset()">
									{{ passwordChangeEmailSent ? "Email sent" : "Change password" }}
								</button>
							</div>
							<button
								class="mt-8 flex w-full flex-row items-center justify-between text-left"
								@click="logoutAllDevices()">
								<div>
									<h1 class="text-sm font-semibold">Logout of all devices</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">
										Logout of all devices and sessions except this one. It might take 24 hours for all devices to be logged out.
									</p>
								</div>
								<ChevronRight class="h-7 w-7 text-gray-500" />
							</button>
							<button
								class="mt-8 flex w-full flex-row items-center justify-between text-left"
								@click="deleteModal = true">
								<div>
									<h1 class="text-sm font-semibold text-red-500">Delete account (Permanent)</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">Permanently delete your account. This action cannot be undone.</p>
								</div>
								<ChevronRight class="h-7 w-7 text-red-500" />
							</button>
						</div>
					</div>
					<modal
						v-if="deleteModal"
						:exit="true"
						title="Delete Account?"
						@close="deleteModal = false">
						<p class="text-base text-gray-500">
							Are you sure you wish to delete your account? This action cannot be undone. If you have any questions or need help, please contact
							<a
								class="text-primary underline"
								href="mailto:hello@scholarly.so"
								>support</a
							>.
						</p>
						<div
							v-if="!isDeletingAccount"
							class="mt-4 flex flex-row items-center space-x-4">
							<button
								class="flex items-center space-x-2 rounded-md border border-red-100 px-8 py-1 text-sm font-bold text-red-800 shadow-sm transition-colors hover:bg-red-100"
								@click="deleteAndLogout()">
								<CheckIcon class="h-4 w-4" />
								<span> Yes </span>
							</button>
							<button
								class="flex items-center space-x-2 rounded-md border border-gray-200 px-8 py-1 text-sm font-bold text-gray-700 shadow-sm transition-colors hover:bg-gray-100"
								@click="deleteModal = false">
								<XMarkIcon class="h-4 w-4" />
								<span> No </span>
							</button>
						</div>
						<div
							v-else
							class="mt-4">
							<spin class="h-6 w-6" />
						</div>
					</modal>
					<div v-if="userSettingsSelectedPage === 'appearance'">
						<div>
							<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Appearance</h1>
							<div class="mt-4 flex flex-row items-center justify-between">
								<div>
									<h1 class="text-sm font-semibold">Theme</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">Choose between light and dark mode.</p>
								</div>
								<button class="disabled cursor-not-allowed rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100">
									Coming soon
								</button>
							</div>
						</div>
					</div>
					<div v-if="userSettingsSelectedPage === 'notifications'">
						<div>
							<h1 class="w-full border-b border-gray-200 pb-4 font-bold">Notifications</h1>
							<div class="mt-4 flex flex-row items-center justify-between">
								<div>
									<h1 class="text-sm font-semibold">Page Alerts</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">Get alerts when someone views your page. These alerts are sent weekly.</p>
								</div>
								<button class="disabled cursor-not-allowed rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100">
									Coming soon
								</button>
							</div>
							<div class="mt-4 flex flex-row items-center justify-between">
								<div>
									<h1 class="text-sm font-semibold">Scholarly Alerts</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">Get alerts about Scholarly updates and new features.</p>
								</div>
								<button class="disabled cursor-not-allowed rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100">
									Coming soon
								</button>
							</div>
							<div class="mt-4 flex flex-row items-center justify-between">
								<div>
									<h1 class="text-sm font-semibold">Study Alerts</h1>
									<p class="mt-1 max-w-[30vw] text-xs text-gray-500">Get alerted to know when to study flashcards.</p>
								</div>
								<button class="disabled cursor-not-allowed rounded-lg border border-gray-200 px-4 py-2 text-sm font-semibold transition-colors hover:bg-gray-100">
									Coming soon
								</button>
							</div>
						</div>
					</div>
					<user-settings-school
						v-if="userSettingsSelectedPage === 'school'"
						:schools="schools" />
					<div v-if="userSettingsSelectedPage === 'import'">
						<UserSettingsImport />
					</div>
				</div>
			</div>
		</template>
	</modal-with-sidebar>
</template>
<script lang="ts" setup>
import { ChevronRight, UniversityIcon, UserCircle } from "lucide-vue-next";
import axios from "axios";
import { ArrowDownTrayIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { IOrganization } from "~/utils/organization";

const { user, organization } = useAuth();
const { userSettingsSelectedPage } = useUserSettings();

const accountPages = [
	{
		id: "my_account",
		name: "My Account",
		icon: UserCircle,
	},
	{
		id: "school",
		name: "School",
		icon: UniversityIcon,
	},
	// {
	// 	id: "appearance",
	// 	name: "Appearance",
	// 	icon: MoonStar,
	// },
	// {
	// 	id: "notifications",
	// 	name: "Notifications",
	// 	icon: BellRing,
	// },
];

const contentPages = [
	{
		id: "import",
		name: "Import",
		icon: ArrowDownTrayIcon,
	},
];

const deleteModal = ref(false);
const name = ref(user!!.value?.name);
const email = ref(user!!.value?.email);
const passwordChangeEmailSent = ref(false);

const schools = useState<IOrganization[]>("schools_cache", () => []);

onMounted(() => {
	if (schools.value.length === 0) {
		axios.get("/orgs/open_schools").then(({ data }) => {
			schools.value = data;
		});
	}
});

watchEffect(() => {
	if (user.value && name.value && name.value !== user.value.name) {
		user.value.name = name.value;

		syncSettingChange({
			name: name.value!!,
		});
	}
});

async function sendPasswordReset() {
	passwordChangeEmailSent.value = true;
	try {
		await axios.post("/forgot_password/request", {
			email: user.value?.email,
		});

		return true;
	} catch (e: any) {
		console.log(e);
		return false;
	} finally {
		setTimeout(() => {
			passwordChangeEmailSent.value = false;
		}, 5000);
	}
}

const profileUploadInput = ref<HTMLInputElement | null>(null);

function openProfileUpload() {
	profileUploadInput.value!!.click();
}

async function uploadProfilePicture(event: Event) {
	const file = (event.target as HTMLInputElement).files!![0];

	try {
		const formData = new FormData();
		formData.append("image", file);

		const { data } = await axios.post("/user/profile_picture", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		user.value!!.profilePictureUrl = data.location;
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to upload profile picture", "error");
	} finally {
		profileUploadInput.value!!.value = "";
	}
}

async function syncSettingChange(changes: object) {
	try {
		await axios.patch("/user/settings", changes);
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to update settings", "error");
	}
}

async function logoutAllDevices() {
	const { getAuthToken } = useAuth();

	try {
		await axios.post("/auth/logout_all_devices", {
			exclude: getAuthToken().value,
		});
		addAlert("Logged out of all devices", "success");
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to logout of all devices", "error");
	}
}

const isDeletingAccount = ref(false);

async function deleteAndLogout() {
	isDeletingAccount.value = true;

	await logoutAllDevices();
	const { setUser, getAuthToken, tempToken } = useAuth();
	const { getPreviousSessionRoute } = useAuth();
	const token = getAuthToken();

	try {
		await axios.post("/auth/delete-user");
		addAlert("Account deleted", "success");

		localStorage.clear();
		setUser(null);
		token.value = null;
		tempToken.value = null;
		getPreviousSessionRoute().value = "home";

		setTimeout(async () => {
			await navigateTo("/");
		}, 500);

		getPosthog().capture("user_delete_account", {});
	} catch (e: any) {
		console.log(e);
		addAlert("Failed to delete account", "error");
	} finally {
		isDeletingAccount.value = false;
	}
}
</script>

import { TextComponentContent } from "@scholarly/components";

export interface Page {
	_id: string;
	ownedBy: string;
	title: string | null;
	emoji: string | null;
	createdAt: Date;
	lastUpdated: Date;
	archived: boolean;
	archivedAt: Date | null;
	lastOpened: Date;
	order: number;
	sharingStatus: {
		visibility: "public" | "private";
		publicToSearchEngines: boolean;
		editors: string[];
		sharedWith: string[];
	};
	parent: string | null;
	components: string[]; //actual components are stored in the component collection

	relationshipCategories: string[] | null;
	aiDescription: string | null;

	//frontend only
	children: Page[];
}

export interface IFile {
	location: string;
	name: string;
	id: string;
	createdAt: Date;
	lastUpdated: Date;
	uploadedBy: string;
	size: number;

	fileType?: string; //Some components don't have this
}

export enum ComponentType {
	TEXT = "text",
	FLASH_CARDS = "flash_cards",
	CHAT = "chat",
	IMAGE = "image",
	ASSISTANT_MESSAGE = "assistant_message",
	CODE_BLOCK = "code_block",
	EQUATION = "equation",
	DIVIDER = "divider",
}

export interface Component {
	_id: string;
	type: ComponentType;
	content: any;
	createdAt: Date;
	createdBy: string;
	modifiedAt: Date;
	lastOpenedAt?: Date; //Only for flashcards & PDFs

	//frontend only
	focus?: boolean | number; //Only used for text components
	flashcard_editor_open?: boolean; //Only flashcard components
	shouldGenTitle?: boolean; // Only flashcard components
}

export interface ComponentText extends Component {
	type: ComponentType.TEXT;
	isEmpty: boolean;
	content: TextComponentContent;

	merge: (json: any) => void;
	sync: () => void;

	fadeIn?: boolean;
}

export interface ComponentCodeBlock extends Component {
	type: ComponentType.CODE_BLOCK;

	content: {
		lines: string[];
		language: string;
	};
}

export interface ComponentEquation extends Component {
	type: ComponentType.EQUATION;

	content: {
		text: string;
	};
}

export interface FlashCard {
	id: string; //UUIDv4
	question: string;
	answer: string;

	questionImage?: any; //type file
	answerImage?: any; //type file
}

export interface ChatMessage {
	senderType: "user" | "assistant";
	senderId: string | null;
	message: Component;
	createdAt: Date | string;
	task: string;
}

export interface ComponentFlashCards extends Component {
	type: ComponentType.FLASH_CARDS;
	content: {
		title: string | null;
		cards: FlashCard[];
		minimized: boolean;

		linkedFileComponents?: {
			id: string;
			addedAt: Date;
		}[]; //objectId of image components
	};
}

export interface ImageComponent extends Component {
	type: ComponentType.IMAGE;
	content: {
		url: string | null;
		file: IFile | null; //never really used in the frontend
		caption?: string | null;

		width?: number | null;
	};

	//frontend only
	openImageGenerator: boolean;
}

export interface IComponentDivider extends Component {
	type: ComponentType.DIVIDER;
	content: {}; //will have stuff later probably
}

<template>
	<modal
		v-if="feedbackModalOpen"
		:exit="true"
		:extra-styles="{ width: '540px' }"
		title="Send Feedback"
		@close="feedbackModalOpen = false">
		<p class="mt-2 text-base text-gray-600">We'd love to hear your feedback! Please share your thoughts on Scholarly below.</p>
		<p class="mt-2 text-base text-gray-600">
			We manually review all feedback and will do our best to address any issues you have. If you have a feature request, please
		</p>
		<div class="mt-4">
			<div class="flex flex-row items-center justify-between">
				<h1 class="text-sm font-bold">Category</h1>
			</div>
			<div class="mt-2">
				<div class="grid grid-cols-3 gap-2 rounded-full bg-gray-200 p-0.5">
					<button
						v-for="cat of categories"
						:key="cat"
						:class="{ '!bg-white !font-semibold': selectedCategory === cat }"
						class="rounded-full bg-gray-200 px-3 py-1 text-base transition-colors hover:bg-white"
						@click="selectedCategory = cat">
						{{ cat }}
					</button>
				</div>
			</div>
		</div>
		<div class="mt-4">
			<h1 class="text-sm font-bold">Short Description</h1>
			<div class="styled-input mt-2">
				<textarea
					v-model="feedback"
					class="h-32 w-full resize-none"
					placeholder="Share a problem you have with Scholarly or suggest a feature..."></textarea>
			</div>
		</div>
		<div>
			<spinning-button
				:disabled="!feedback"
				:spinning="submittingFeedback"
				class="mt-4"
				@click="submitFeedback">
				Submit Feedback
			</spinning-button>
		</div>
	</modal>
</template>
<script lang="ts" setup>
import axios from "axios";

const feedback = ref("");
const submittingFeedback = ref(false);

const categories = ["Bug Report", "Feature Request", "General Feedback"];
const selectedCategory = ref<(typeof categories)[number]>("General Feedback");
const { feedbackModalOpen } = useFeedback();

const { user } = useAuth();

async function submitFeedback() {
	if (submittingFeedback.value) return;

	submittingFeedback.value = true;

	try {
		await axios.post(user.value ? "/feedback" : "/feedback/anon", {
			feedback: feedback.value,
			category: selectedCategory.value,
			currentRoute: useRoute().fullPath,
		});

		addAlert("Feedback submitted!", "success");
		feedbackModalOpen.value = false;
		feedback.value = "";
	} catch (e) {
		addAlert("Failed to submit feedback, please try again", "error");
		console.error(e);
	} finally {
		submittingFeedback.value = false;
	}
}
</script>

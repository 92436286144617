import { useLocalStorage } from "@vueuse/core";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import PageModification = PageSyncPipeline.PageModification;

export const usePageSyncPipeline = () => {
	const storage = useLocalStorage<PageModification<any>[]>("page_sync_pipeline_page_modifications", [], {
		deep: true,
	});

	return {
		storage,
	};
};

<template>
	<client-only>
		<alerts-container />
		<user-settings-modal
			v-if="settingsModalOpen"
			@close="settingsModalOpen = false" />
		<!--	<free-trial-modal />-->
		<free-trial-expire-modal />
		<content-search-modal />
		<stripe-purchase-modal />
		<div class="flex-no-wrap flex h-screen overflow-hidden">
			<sidebar-transition>
				<sidebar-full
					v-show="!preferences.sidebar.minimized"
					class="sidebar-width"
					@close-sidebar="preferences.sidebar.minimized = true"></sidebar-full>
			</sidebar-transition>
			<slot />
		</div>
	</client-only>
</template>
<script lang="ts" setup>
import axios from "axios";

const { preferences } = useUserPreferences();
const { settingsModalOpen } = useUserSettings();

const { user } = useAuth();

onMounted(() => {
	// if (!user.value?.hasReceivedFreeTrial) {
	// 	giveFreeTrialToUser();
	// }
});

async function giveFreeTrialToUser() {
	getPosthog().capture("free_trial_received", {});

	user.value!.plan = {
		type: Plan.ULTIMATE,
		subscriptionId: "unknown",
		createdAt: new Date(),
		updatedAt: new Date(),
		endAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
		isFreeTrial: true,
		cancelled: false,
		cancelledAt: new Date(),
	};

	try {
		await axios.post("/stripe/free_trial");
	} catch (e) {
		console.error(e);
	}
}
</script>
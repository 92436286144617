<template>
	<opacity-transition>
		<div
			v-if="show"
			ref="container"
			@click="handleClick"
			:style="position"
			class="translate-z-0 fixed z-50">
			<div class="rounded-md border border-gray-150 bg-white px-1 py-1 text-sm text-gray-600 shadow-sm">
				<slot />
			</div>
		</div>
	</opacity-transition>
</template>
<script lang="ts" setup>
const props = defineProps<{
	show: boolean;
	mousePosition: {
		x: number;
		y: number;
	};
}>();

const position = ref({
	left: "0",
	top: "0",
});

const container = ref<HTMLElement | null>(null);

watch(
	() => props.mousePosition,
	(value) => {
		const x = value.x;
		const y = value.y;

		position.value = {
			left: `${x}px`,
			top: `${y}px`,
		};
	},
	{ immediate: true },
);

const emits = defineEmits({
	close: () => true,
});

function handleExit(e: any) {
	if (e.keyCode == 27) {
		emits("close");
	}
}

function handleClickOutside(e: MouseEvent) {
	if (e.target instanceof HTMLButtonElement) return;

	if (container.value) {
		const rect = container.value.getBoundingClientRect();
		if (e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom) {
			emits("close");
		}
	}
}

onMounted(() => {
	document.addEventListener("keydown", handleExit);
	setTimeout(() => {
		document.addEventListener("click", handleClickOutside);
		document.addEventListener("contextmenu", handleClickOutside);
	}, 200);
});

onUnmounted(() => {
	document.removeEventListener("keydown", handleExit);
	document.removeEventListener("click", handleClickOutside);
	document.removeEventListener("contextmenu", handleClickOutside);
});

function handleClick(e: MouseEvent) {
	const target = e.target as HTMLElement;
	if (target.closest("[data-sc-dropdown-btn-close]")) {
		emits("close");
	}
}
</script>

<template>
	<teleport to="body">
		<div>
			<div
				ref="backdrop"
				:class="{ 'opacity-100': !shouldClose, 'dark-curtains': !backdropColor }"
				aria-hidden="true"
				class="fixed inset-0 opacity-0 transition-none transition-opacity"
				style="z-index: 40"></div>
			<div
				ref="container"
				aria-modal="true"
				class="inset-center-xy fixed"
				style="z-index: 999">
				<slot />
			</div>
			<slot name="outside" />
		</div>
	</teleport>
</template>
<script lang="ts" setup>
import type { Ref } from "vue";
import { PopupContent } from "~/composables/usePopupContent";

const emit = defineEmits(["close"]);
const container = ref<HTMLElement | null>(null);

const props = defineProps<{
	shouldClose?: boolean;
	backdropColor?: string;
}>();

const { registerContent, destroyContent, closeSpecific } = usePopupContent();
let popupElement: PopupContent | undefined = undefined;

watchEffect(() => {
	if (props.shouldClose) {
		closeSpecific(popupElement!!);
	}
});

function close() {
	emit("close");
	shouldClose.value = true;
}

const shouldClose = ref(false);
const backdrop = ref() as Ref<HTMLElement>;

onMounted(() => {
	popupElement = registerContent({
		closeListener: () => close(),
		element: container.value!!,
	});

	//add class props.backdropColor to ref.backdrop
	if (props.backdropColor) {
		(backdrop.value as HTMLElement)!!.classList.add(props.backdropColor);
	}
});

onUnmounted(() => {
	destroyContent(popupElement!!);
});
</script>

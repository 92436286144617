<template>
	<dropdown-container dropdown-width="250px">
		<template #default="{ open }">
			<dropdown-open-button
				:class="{ 'scholarly-button-normal-dark': dark === true }"
				class="scholarly-button-normal">
				<span> Language: {{ selectedLanguage }} </span>
				<animated-chevron-icon
					:mode="open ? 'up' : 'down'"
					class="h-4 w-4" />
			</dropdown-open-button>
		</template>
		<template #dropdown>
			<div
				:class="{ 'styled-input-with-icon-dark': dark }"
				class="styled-input-with-icon">
				<input
					v-model="search"
					data-no-close-popup="true"
					placeholder="Search.."
					type="text" />
				<MagnifyingGlassIcon class="h-4 w-4" />
			</div>
			<div class="mt-2 h-64 overflow-y-auto">
				<dropdown-item
					v-for="data in getLanguages"
					:dark="dark"
					:icon="data.emoji"
					@click="selectLanguage(data)">
					{{ data.name }}
				</dropdown-item>
			</div>
		</template>
	</dropdown-container>
</template>
<script lang="ts" setup>
import { Language, matchLanguages } from "~/utils/language";
import { useLanguage } from "~/composables/useLanguage";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

const props = defineProps<{
	dark?: boolean;
}>();
const { selectedLanguage } = useLanguage();

if (!selectedLanguage.value) {
	selectedLanguage.value = "English";
}

const search = ref("");

const getLanguages = computed(() => {
	if (search.value.length === 0) {
		return languages;
	}
	return matchLanguages(search.value);
});

function selectLanguage(language: Language) {
	selectedLanguage.value = language.name;
}
</script>

<template>
	<div
		ref="container"
		class="flex"
		@mousedown="stopHover()"
		@mouseenter="startHover()"
		@mouseleave="stopHover()">
		<slot />
	</div>
	<teleport to="body">
		<opacity-transition>
			<span
				v-if="container && hovered"
				:style="position"
				class="fixed rounded bg-neutral-800 px-2 py-1 text-xs font-semibold text-white"
				style="z-index: 99999">
				{{ props.tooltip }}
			</span>
		</opacity-transition>
	</teleport>
</template>
<script lang="ts" setup>
const TOOLTIP_DELAY = 400;
const TOO_CLOSE_TO_BOTTOM_OF_SCREEN = 100;

const container = ref<HTMLElement | null>(null);
const hovered = ref(false);

const props = defineProps<{
	tooltip: string;
}>();

const position = reactive({
	top: "",
	left: "",
});

let timeout: NodeJS.Timeout;

onMounted(() => {
	computeTooltipPosition();
});

function startHover() {
	computeTooltipPosition();
	timeout = setTimeout(() => {
		hovered.value = true;
	}, TOOLTIP_DELAY);
}

function stopHover() {
	clearTimeout(timeout);
	hovered.value = false;
}

function computeTooltipPosition() {
	if (!container.value) return {};

	const width = container.value.getBoundingClientRect().width;
	const left = container.value.getBoundingClientRect().left + width / 2 - 50;

	const top = container.value.getBoundingClientRect().top + 32;

	position.top = top + "px";
	position.left = left + "px";

	// If too close to bottom, make it appear above
	if (top + TOO_CLOSE_TO_BOTTOM_OF_SCREEN > window.innerHeight) {
		position.top = container.value.getBoundingClientRect().top - 28 + "px";
	}

	// if tooltip is too close to left of the screen, make it appear on the right
	if (left < 0) {
		position.left = container.value.getBoundingClientRect().left + container.value.getBoundingClientRect().width + 12 + "px";
		position.top = container.value.getBoundingClientRect().top + "px";
	}
}
</script>
